import React, {useContext} from "react";
import _ from "lodash";
import {fieldTypes, getColorFromCategoria, testTime} from "../services/utils";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slide,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DateField, DateTimeField, TimeField, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AccessTime, ContentCopy, Event, OpenInNew, VideoCameraFront} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {API_URL} from "../config";
import CustomTooltip from "./CustomTooltip";
import {customTheme} from "../theme/customTheme";
import {useParams} from "react-router-dom";
import {GlobalContext} from "../state/global";
import dayjs from "dayjs";
import {CustomFileUploader} from "./CustomFileUploader";

const CustomField = ({userId, formik, field, disabled, refetch}) => {
    const [globalState, dispatch] = useContext(GlobalContext)
    const {id: user_id} = useParams()
    const fieldType = _.find(fieldTypes, ['type', field.type])

    function getOptions() {
        switch (field.id) {
            case "luogoArrivo":
                return formik.values['mezzoDiTrasporto'] === "In aereo" ? ["Linate", "Malpensa"]
                    : formik.values['mezzoDiTrasporto'] === "In treno" ? ["Milano Centrale", "Milano Garibaldi"]
                        : (field?.values || [])
            default:
                return field?.values || []

        }
    }

    function getValue() {
        return field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]
    }

    function getOtherValue() {
        const val = getValue()
        switch (field.id) {
            case "luogoArrivo":
                return getOptions().includes(val) ? "" : val
            case "luogoPartenzaRitorno":
                return field.values.includes(val) ? "" : val
            default:
                return val

        }
    }

    //console.log("formik:",formik)

    return (
        <Slide in={true} exit={true}
               children={<Grid item xs={12} md={field?.cols?.md || fieldType?.md || 6}>
                   {(() => {
                       switch (fieldType) {
                           case fieldTypes.CHECK_BOX:
                               return <FormControl>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={<Checkbox
                                               id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               disabled={field.precompiled || disabled}
                                               checked={typeof getValue() === "string" ? getValue() === 'si' : getValue()}
                                               //onChange={formik.handleChange}
                                               //onBlur={formik.handleBlur}
                                               onChange={(event, checked) => {
                                                   if (!field.precompiled && !disabled) {
                                                       if(typeof getValue() === "string") {
                                                           formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, checked ? 'si' : 'no')
                                                           formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                                       } else {
                                                           formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, checked)
                                                           formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                                       }
                                                   }
                                               }}
                                           />} label={field.label}/>
                                   </FormGroup>
                               </FormControl>
                           case fieldTypes.RADIO_GROUP:
                               return <FormControl fullWidth disabled={field.precompiled || disabled}>
                                   <Typography variant={'body2'}>{field.label || field.headerName}</Typography>
                                   <RadioGroup
                                       row
                                       value={getValue()}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                   >
                                       {
                                           (field.values || getOptions()).map((radio) =>
                                               <FormControlLabel key={radio.value} value={radio.value} control={
                                                   <Radio required/>} label={radio.label}/>
                                           )
                                       }
                                       {field.other
                                           && (field.condition && formik.values[field.condition.id] === field.condition.value)
                                           && <FormControlLabel
                                               checked={!getOptions().includes(getValue())}
                                               control={<Radio/>} label={<TextField
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               value={getOtherValue()}
                                               onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                               onBlur={formik.handleBlur}
                                               variant={"standard"} placeholder={'Altro:'}/>
                                           }/>}
                                   </RadioGroup>
                               </FormControl>
                           case fieldTypes.SELECT:
                               return <FormControl variant="standard" fullWidth>
                                   <FormHelperText>{field.label}</FormHelperText>
                                   <Select
                                       disabled={field.precompiled || disabled}
                                       label={field.label}
                                       id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                       //value={dati[field.id]}
                                       value={getValue()}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       //error={Boolean(formik.errors[field.id])}
                                       //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                       //helperText={field.label}
                                   >
                                       {(getOptions()).map(v =>
                                           <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           case fieldTypes.DATE:
                               //console.log("DATE >> getValue():",getValue())
                               //console.log("DATE >> getter:",field.getter ? formik.values[field.getter][field.id] : '')
                               //console.log("DATE >> no getter:",formik.values[field.id])
                               return <LocalizationProvider dateAdapter={AdapterDayjs}>
                                   <DateField
                                       fullWidth
                                       variant={"standard"}
                                       sx={{width: '100%'}}
                                       format={'DD/MM/YYYY'}
                                       disabled={field.precompiled || disabled}
                                       value={getValue() ?
                                           dayjs(getValue()?._seconds ?
                                               (getValue()._seconds * 1000)
                                               : getValue())
                                           : null}
                                       onChange={(date) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       label={field.label ?? ''}
                                       InputProps={{
                                           startAdornment: <Event fontSize={'small'} position="start" color={'primary'} sx={{marginRight: 1}}/>,
                                       }}
                                   />
                               </LocalizationProvider>
                           /*<LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDatePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}
                                                      InputProps={{
                                                          startAdornment: <Event fontSize={'small'} position="start" color={'primary'} sx={{marginRight: 1}}/>,
                                                      }}
                                           />}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(getValue()?._seconds ?
                                           getValue()._seconds * 1000
                                           : getValue()
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy"/>
                               </LocalizationProvider>*/
                           case fieldTypes.DATE_TIME:
                               return <LocalizationProvider dateAdapter={AdapterDayjs}>
                                   <DateTimeField
                                       fullWidth
                                       variant={"standard"}
                                       sx={{width: '100%'}}
                                       ampm={false}
                                       format={'DD/MM/YYYY, HH:mm'}
                                       disabled={field.precompiled || disabled}
                                       value={getValue() ?
                                           dayjs(getValue()?._seconds ?
                                               (getValue()._seconds * 1000)
                                               : getValue())
                                           : null}
                                       onChange={(date) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       label={field.label ?? ''}
                                       InputProps={{
                                           startAdornment: <Event fontSize={'small'} position="start" color={'primary'} sx={{marginRight: 1}}/>,
                                       }}
                                   />
                               </LocalizationProvider>
                           /*<LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDateTimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}
                                           />}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(getValue()?._seconds ?
                                           getValue()._seconds * 1000
                                           : getValue()
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy, HH:mm"/>
                               </LocalizationProvider>*/
                           case fieldTypes.TIME:
                               return <LocalizationProvider dateAdapter={AdapterDayjs}>
                                   <TimeField
                                       fullWidth
                                       variant={"standard"}
                                       sx={{width: '100%'}}
                                       ampm={false}
                                       format={'HH:mm'}
                                       disabled={field.precompiled || disabled}
                                       value={getValue() ?
                                           dayjs(getValue()?._seconds ?
                                               (getValue()._seconds * 1000)
                                               : getValue())
                                           : null}
                                       onChange={(date) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       label={field.label ?? ''}
                                       InputProps={{
                                           startAdornment: <AccessTime fontSize={'small'} position="start" color={'primary'} sx={{marginRight: 1}}/>,
                                       }}
                                   />
                               </LocalizationProvider>
                           /*<LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <TimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]) || ''}
                                       inputFormat="HH:mm"/>
                               </LocalizationProvider>*/
                           case fieldTypes.URL:
                               return getValue() ?
                                   <Box display={'flex'} justifyContent={'start'} alignItems={'center'}>
                                       <CustomTooltip
                                           title={'Apri link in un\'altra finestra'}
                                           children={<Button href={getValue()}
                                                             target={'_blank'}
                                                             color={'accent'}
                                                             size={'small'}
                                                             variant={'contained'}
                                                             sx={{paddingBlock: 1, color: 'white'}}
                                                             onClick={(event) => event.stopPropagation()}
                                                             endIcon={<OpenInNew/>}>
                                               {field.label}
                                           </Button>}/>
                                       <CustomTooltip
                                           title={"Copia link"}
                                           children={<IconButton color={'primary'}
                                                                 onClick={() =>
                                                                     navigator.clipboard.writeText(getValue())
                                                                 }>
                                               <ContentCopy fontSize={'small'}/>
                                           </IconButton>}/>
                                   </Box> : ''
                           case fieldTypes.ACCESS_TYPE:
                               if(formik.values.partecipazione === 'si') {
                                   return <Stack alignItems={'center'}>
                                       {formik.values.qrCodeUrl ?
                                           <Stack p={1} borderRadius={'1rem'}
                                                  border={`1px dashed ${customTheme.palette.accent.main}`}>
                                               <img src={formik.values.qrCodeUrl}
                                                    style={{width: '150px'}}/>
                                               <Chip label={`Area: ${formik.values.area || 'Not assigned'}`}
                                                     /*sx={{
                                                         fontWeight:'bold',
                                                         background: formik.values.area || getColorFromCategoria(formik.values?.categoria) || '#eeeeee',
                                                         color: (!!formik.values.area ? customTheme.palette.getContrastText(formik.values.area)
                                                             : !!formik.values?.categoria ? customTheme.palette.getContrastText(getColorFromCategoria(formik.values?.categoria))
                                                                 : 'inherit')
                                                     }}*/
                                               />
                                           </Stack>
                                           : globalState?.authState?.isAdmin ?
                                               <Button variant={'contained'}
                                                       onClick={async (event) => {
                                                           event.stopPropagation()
                                                           await axios.post(API_URL + 'users/generate-qr-code/' + userId)
                                                               .then(() => window.location.reload())
                                                       }}>
                                                   Genera QR code per questo utente
                                               </Button> : null
                                       }
                                   </Stack>
                               } else if(formik.values.partecipazione === 'online') {
                                   return <Stack direction={"row"} spacing={1} justifyContent={'center'}>
                                       <CustomTooltip title={"Apri link streaming"} children={
                                           <Button href={formik.values.urlStreaming
                                               || `https://URL.it/${userId}`}
                                                   //href={`https://believetoachieve2023.canon.it/live/${userId}`}
                                                   target={'_blank'}
                                                   color={'accent'}
                                                   size={'small'}
                                                   variant={'outlined'}
                                                   endIcon={<VideoCameraFront/>}
                                           >
                                               Link allo streaming
                                           </Button>
                                       }/>
                                       <CustomTooltip title={"Copia link streaming"} children={
                                           <IconButton
                                               color={'accent'}
                                               variant={'outlined'}
                                               onClick={async (event) => {
                                                   event.stopPropagation()
                                                   await navigator.clipboard.writeText(
                                                       `https://believetoachieve2023.canon.it/live/${userId}`)
                                               }}>
                                               <ContentCopy/>
                                           </IconButton>
                                       }/>
                                   </Stack>
                               }
                               return null

                           case fieldTypes.TEXT_BOX:
                               return <TextField id={field.id}
                                                 name={field.id}
                                                 disabled={field.precompiled || disabled}
                                                 multiline={true}
                                                 minRows={2}
                                                 maxRows={3}
                                                 value={formik.values[field.id]}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"outlined"}
                                                 sx={{width: '100%'}}/>
                           case fieldTypes.NUMBER:
                               /** TODO: fix Number check */
                           case fieldTypes.EMAIL:
                               /** TODO: fix E-mail check */
                           case fieldTypes.TEXT:
                               let val = null
                               if (field.id === 'id')
                                   val = user_id

                               return <TextField id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                                 disabled={field.precompiled || disabled}
                                                 value={val || getValue()}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>

                           case fieldTypes.DIVIDER:
                               return <Divider
                                   children={field?.label && <Chip size={'small'} label={field?.label || ''}/>}/>

                           case fieldTypes.COLOR:
                               return <Chip label={`${field?.label}: ${getValue() || 'Non definito'}`}
                                            sx={{
                                                fontWeight:'bold',
                                                background: getValue() || getColorFromCategoria(formik.values?.categoria) || '#eeeeee',
                                                color: (!!getValue() ? customTheme.palette.getContrastText(getValue())
                                                    : !!formik.values?.categoria ? customTheme.palette.getContrastText(getColorFromCategoria(formik.values?.categoria))
                                                        : 'inherit')
                                            }}/>

                           case fieldTypes.FILE_UPLOAD:
                               return <Stack>
                                   <FormHelperText>Carica {field.label}</FormHelperText>
                                   <CustomFileUploader field={field.id}
                                                       fileLabel={field.label}
                                                       uploaded={getValue()}
                                                       refetch={refetch}
                                   />
                               </Stack>

                           default:
                               return getValue()
                       }
                   })()}
               </Grid>}
        />)
}

export default CustomField