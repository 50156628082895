import {Chip} from "@mui/material";
import React from "react";

export const FormCompletedCustomChip = ({completionField, long = false}) => {
    return (
        <Chip
            variant={'outlined'}
            size={'small'}
            color={!!completionField ? 'success' : 'error'}
            label={completionField ?
                ((long ? 'Completion Date: ' : "") + (completionField?._seconds ?
                    new Date((completionField?._seconds * 1000))
                        .toLocaleString('it-IT',
                            {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'})
                    : completionField ?
                        new Date(completionField)
                            .toLocaleString('it-IT',
                                {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'})
                        : ""))
                : 'Not completed'}
        />
    );
}

export default FormCompletedCustomChip