import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBvww2FJXVhDCeF6FCg4m7V6sliI4n-SJI",
    authDomain: "baush-e-lomb-2024.firebaseapp.com",
    projectId: "baush-e-lomb-2024",
    storageBucket: "baush-e-lomb-2024.appspot.com",
    messagingSenderId: "907217759833",
    appId: "1:907217759833:web:e5e9520d5ff4b79f330b48"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}